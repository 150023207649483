import { assertExhaustive, type Block, BlockType } from '@lp-lib/game';

import { GuessWhoBlockCoordinatorController } from '../../GuessWho/GuessWhoBlockCoordinatorController';
import { HeadToHeadBlockCoordinatorController } from '../../HeadToHead/HeadToHeadCoordinatorController';
import { IcebreakerBlockCoordinatorController } from '../../Icebreaker/IcebreakerCoordinatorController';
import { InstructionBlockCoordinatorController } from '../../Instruction/InstructionBlockCoordinatorController';
import { JeopardyBlockCoordinatorController } from '../../Jeopardy/JeopardyBlockCoordinatorController';
import { MarketingBlockCoordinatorController } from '../../Marketing/MarketingBlockCoordinatorController';
import { OverRoastedBlockCoordinatorController } from '../../OverRoasted/OverRoastedCoordinatorController';
import { TitleBlockV2CoordinatorController } from '../../TitleV2/TitleBlockV2CoordinatorController';

export type CoordinatorControllerProps<B extends Block> = {
  selectedBlock: B;
};

export function CoordinatorController<B extends Block>(
  props: CoordinatorControllerProps<B>
): JSX.Element | null {
  const { selectedBlock } = props;
  const blockType = selectedBlock.type;

  switch (blockType) {
    case BlockType.QUESTION:
    case BlockType.CREATIVE_PROMPT:
    case BlockType.RAPID:
    case BlockType.SCOREBOARD:
    case BlockType.SPOTLIGHT:
    case BlockType.SPOTLIGHT_V2:
    case BlockType.TEAM_RELAY:
    case BlockType.RANDOMIZER:
    case BlockType.MULTIPLE_CHOICE:
    case BlockType.MEMORY_MATCH:
    case BlockType.PUZZLE:
    case BlockType.ROUND_ROBIN_QUESTION:
    case BlockType.DRAWING_PROMPT:
    case BlockType.HIDDEN_PICTURE:
    case BlockType.AI_CHAT:
      return null;
    case BlockType.OVERROASTED:
      return (
        <OverRoastedBlockCoordinatorController selectedBlock={selectedBlock} />
      );
    case BlockType.INSTRUCTION:
      return (
        <InstructionBlockCoordinatorController selectedBlock={selectedBlock} />
      );
    case BlockType.GUESS_WHO:
      return (
        <GuessWhoBlockCoordinatorController selectedBlock={selectedBlock} />
      );
    case BlockType.ICEBREAKER:
      return (
        <IcebreakerBlockCoordinatorController selectedBlock={selectedBlock} />
      );
    case BlockType.MARKETING:
      return (
        <MarketingBlockCoordinatorController selectedBlock={selectedBlock} />
      );
    case BlockType.JEOPARDY:
      return (
        <JeopardyBlockCoordinatorController selectedBlock={selectedBlock} />
      );
    case BlockType.HEAD_TO_HEAD:
      return (
        <HeadToHeadBlockCoordinatorController selectedBlock={selectedBlock} />
      );
    case BlockType.TITLE_V2:
      return (
        <TitleBlockV2CoordinatorController selectedBlock={selectedBlock} />
      );
    default:
      assertExhaustive(blockType);
      return null;
  }
}
