import { type RemoteStreamType, type UID } from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useRef } from 'react';
import { useLatest } from 'react-use';

import { GamePlayHostVideo } from '../../components/Game/Blocks/Common/GamePlay';
import {
  useIsLiveGamePlay,
  useOndGameState,
} from '../../components/Game/hooks';
import { useHost, useParticipantFlags } from '../../components/Player';
import { useAudioEnabled } from '../../components/Venue/VenuePlaygroundProvider';
import {
  useDebugStream,
  useIsCoreChannelJoined,
  useRTCService,
} from '../../components/WebRTC';
import {
  getFeatureQueryParam,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import logger from '../../logger/logger';
import { type IRTCService } from '../../services/webrtc';

const log = logger.scoped('host-stream');

const autoRecoveryEnabled = getFeatureQueryParam(
  'host-stream-quality-auto-recovery'
);

function checkIfUidMatch(agoraUid: unknown, sessionUid: unknown): boolean {
  return agoraUid === sessionUid;
}

class StreamQualityAutoRecover {
  private uid?: UID;
  constructor(
    private rtcService: IRTCService,
    private checkInterval = 5000,
    private timerId: ReturnType<typeof setTimeout> | null = null
  ) {}
  notify(uid: UID, streamType: RemoteStreamType) {
    if (this.uid && this.uid !== uid) this.stop();
    if (streamType === 1) {
      this.uid = uid;
      this.start();
    } else {
      this.uid = undefined;
      this.stop();
    }
  }
  private start() {
    this.stop();
    const fix = () => {
      if (!this.uid) return;
      this.rtcService.setRemoteVideoStreamType(this.uid, 0);
      log.info('detect low quality host stream, attempt to auto recovery');
      this.timerId = setTimeout(fix, this.checkInterval);
    };
    fix();
  }
  private stop() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }
  clear() {
    this.uid = undefined;
    this.stop();
  }
}

interface LiveHostStreamPlayerProps {
  className: string;
}

const LiveHostStreamView = ({
  className,
}: LiveHostStreamPlayerProps): JSX.Element => {
  const host = useHost();
  const hostClientId = host?.clientId ?? null;
  const flags = useParticipantFlags(hostClientId);
  const audioEnabled = useAudioEnabled();
  const rtcService = useRTCService('stage');
  const recover = useInstance(() => new StreamQualityAutoRecover(rtcService));
  const ref = useRef<HTMLDivElement>(null);
  const audioEnabledRef = useLatest(audioEnabled);
  useDebugStream(ref.current, hostClientId, rtcService);
  const stageJoined = useIsCoreChannelJoined('stage');

  const publishedCallback = useCallback(
    async (uid: UID, mediaType: 'audio' | 'video') => {
      if (!checkIfUidMatch(uid, hostClientId)) return;
      if (mediaType === 'audio') {
        if (audioEnabledRef.current) rtcService.playAudio(uid);
      }
      if (mediaType === 'video') {
        if (ref.current) rtcService.playVideo(uid, ref.current);
        try {
          await rtcService.setRemoteVideoStreamType(uid, 0);
        } catch (error) {
          rtcService.log.error('setRemoteVideoStreamType failed', error);
        }
      }
    },
    [audioEnabledRef, hostClientId, rtcService]
  );

  const unpublishedCallback = useCallback(
    (uid: UID, mediaType: 'audio' | 'video') => {
      if (!checkIfUidMatch(uid, hostClientId)) return;
      if (mediaType === 'audio') {
        rtcService.stopAudio(uid);
      }
      if (mediaType === 'video') {
        rtcService.stopVideo(uid);
        recover.clear();
      }
    },
    [hostClientId, recover, rtcService]
  );

  const streamTypeChangedCallback = useCallback(
    (uid: UID, streamType: RemoteStreamType) => {
      if (!checkIfUidMatch(uid, hostClientId)) return;
      recover.notify(uid, streamType);
    },
    [hostClientId, recover]
  );

  useEffect(() => {
    if (!hostClientId) return;
    if (audioEnabled) {
      rtcService.playAudio(hostClientId);
    } else {
      rtcService.stopAudio(hostClientId);
    }
  }, [hostClientId, rtcService, audioEnabled]);

  useEffect(() => {
    if (!stageJoined || !hostClientId) return;
    const [audioTrack, videoTrack] = rtcService.getTracksByUid(hostClientId);
    if (audioTrack) publishedCallback(hostClientId, 'audio');
    if (videoTrack) publishedCallback(hostClientId, 'video');
    const disposers: (() => void)[] = [];
    disposers.push(rtcService.on('remote-user-published', publishedCallback));
    disposers.push(
      rtcService.on('remote-user-unpublished', unpublishedCallback)
    );
    if (autoRecoveryEnabled) {
      disposers.push(
        rtcService.on('stream-type-changed', streamTypeChangedCallback)
      );
    }
    return () => {
      disposers.forEach((disposer) => disposer());
      recover.clear();
    };
  }, [
    stageJoined,
    publishedCallback,
    recover,
    rtcService,
    streamTypeChangedCallback,
    unpublishedCallback,
    hostClientId,
  ]);

  const showVideo = flags?.video && flags?.hasCamera !== false;

  return (
    <div
      ref={ref}
      className={`${className} ${showVideo ? 'block' : 'hidden'}`}
    ></div>
  );
};

export function HostStreamView(): JSX.Element | null {
  const isOndGamePlay = !useIsLiveGamePlay();
  const ondGameState = useOndGameState();
  const hostStreamViewEnabled = useFeatureQueryParam('host-stream-view');

  if (!hostStreamViewEnabled) return null;
  if (isOndGamePlay)
    return ondGameState === 'ended' ? null : <GamePlayHostVideo />;
  return <LiveHostStreamView className='w-screen h-full z-0 absolute' />;
}
