import { GreenScreenValuesUtils } from '@lp-lib/game';

import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { getLogger } from '../../logger/logger';
import {
  type CustomVideoQualityPreset,
  profileForCustomVideo,
} from '../../services/webrtc';
import {
  type VirtualBackgroundEffects,
  VirtualBackgroundMixer,
  type VirtualBackgroundOptions,
} from '../../services/webrtc/virtual-background';
import { xDomainifyUrl } from '../../utils/common';
import { Emitter } from '../../utils/emitter';
import {
  UnplayableImageImpl,
  UnplayableVideoImpl,
} from '../../utils/unplayable';
import {
  type IVideoEffectsMixer,
  type IVideoStreamMixer,
  type IVirtualBackgroundMixer,
  type MixerFeatures,
  type MixMode,
  type VirtualBackgroundMixerEvents,
} from '../Device/video-stream-mixer';
import { type VideoEffectsSettings } from '../VideoEffectsSettings/types';
import { VideoEffectsSettingsUtils } from '../VideoEffectsSettings/VideoEffectsSettingsUtils';
import {
  type TrackId,
  TrackInitConfigBuilder,
  VideoMixer,
} from '../VideoMixer';

const log = getLogger().scoped('cohost-vm');

export class CohostVideoMixer
  implements IVideoStreamMixer, IVirtualBackgroundMixer, IVideoEffectsMixer
{
  readonly features: MixerFeatures[] = [
    'videoEffects',
    'virtualBackgroundEffects',
  ];
  private vm: VideoMixer;
  private vmCameraTrackId: {
    vm: TrackId;
    raw: string;
  } | null = null;
  private vmStageTrackId: TrackId | null = null;
  private videoEffectsSettings = VideoEffectsSettingsUtils.WithDefaults(null);
  private cameraMediaStreamTrack: MediaStreamTrack | null = null;
  private vmPodiumTrack: TrackId | null = null;
  private vbgMixer: VirtualBackgroundMixer;
  private emitter = new Emitter<VirtualBackgroundMixerEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(
    videoEncoderConfig: CustomVideoQualityPreset,
    virtualBackground?: VirtualBackgroundOptions,
    readonly fadeMs = getFeatureQueryParamNumber('cohost-fade-ms')
  ) {
    const config = profileForCustomVideo(videoEncoderConfig);
    this.vbgMixer = new VirtualBackgroundMixer(
      {
        enabled: false,
        ...virtualBackground,
      },
      log
    );
    this.vm = new VideoMixer(
      {
        drawFps: config.framerate,
        renderWidth: config.width,
        renderHeight: config.height,
      },
      false,
      'raf-accumulated'
    );

    this.vm.play();
  }

  async init() {
    await this.vbgMixer.init();
  }

  async destroy(): Promise<void> {
    await this.vm.destroy();
    this.vmCameraTrackId = null;
    this.cameraMediaStreamTrack = null;
    this.emitter.emit('virtual-background-track-updated', null);
    log.debug('destroyed');
  }

  get mixMode(): MixMode {
    return 'full';
  }

  async setVirtualBackgroundEffects(effects: VirtualBackgroundEffects | null) {
    return this.vbgMixer.setVirtualBackgroundEffects(effects);
  }

  getVideoEffectsSettings(): Readonly<VideoEffectsSettings> {
    return this.videoEffectsSettings;
  }

  async enableVirtualBackground() {
    return this.vbgMixer.toggle(true);
  }

  disableVirtualBackground() {
    return this.vbgMixer.toggle(false);
  }

  get virtualBackgroundEnabled() {
    return this.vbgMixer.enabled;
  }

  get virtualBackgroundAvailable() {
    return this.vbgMixer.available;
  }

  get virtualBackgroundTrack() {
    return this.cameraMediaStreamTrack;
  }

  async updateVideoEffectsSettings(
    settings: Partial<VideoEffectsSettings>
  ): Promise<void> {
    const updatedSettings = {
      ...this.videoEffectsSettings,
      ...settings,
    };

    const cameraDirtyProps = ['greenScreen', 'boundingBox'] as const;
    const cameraDirty = cameraDirtyProps.some(
      (p) =>
        JSON.stringify(updatedSettings[p]) !==
        JSON.stringify(this.videoEffectsSettings[p])
    );
    const stageDirty =
      JSON.stringify(updatedSettings.stage) !==
      JSON.stringify(this.videoEffectsSettings.stage);
    const podiumDirty =
      JSON.stringify(settings.podium) !==
      JSON.stringify(this.videoEffectsSettings.podium);
    const podiumBoundingBoxDirty =
      JSON.stringify(settings.podiumBoundingBox) !==
      JSON.stringify(this.videoEffectsSettings.podiumBoundingBox);

    this.videoEffectsSettings = updatedSettings;

    const ops = [];

    if (cameraDirty) ops.push(this.rebuildCameraTrack());
    if (stageDirty) ops.push(this.rebuildStageTrack());
    if (podiumDirty || podiumBoundingBoxDirty)
      ops.push(this.rebuildPodiumTrack());

    await Promise.all(ops);
  }

  async updateCameraTrack(track: MediaStreamTrack | null): Promise<void> {
    if (track === this.cameraMediaStreamTrack) return;
    log.info('updating camera track, input track info', {
      trackId: track?.id ?? null,
      trackLabel: track?.label ?? null,
      trackStatus: track?.readyState ?? null,
    });
    const outputTrack = await this.vbgMixer.updateCameraTrack(track);
    log.info('updating camera track, output track info', {
      trackId: outputTrack?.id ?? null,
      trackLabel: outputTrack?.label ?? null,
      trackStatus: outputTrack?.readyState ?? null,
    });
    this.cameraMediaStreamTrack = outputTrack;
    this.emitter.emit(
      'virtual-background-track-updated',
      this.cameraMediaStreamTrack
    );
    await this.rebuildCameraTrack();
  }

  get outputVideoTrack(): MediaStreamTrack {
    const stream = this.vm.getOutputMediaStream();
    const track = stream.getVideoTracks()[0];
    if (!track) throw new Error('No Video Track');
    return track;
  }

  private async rebuildStageTrack() {
    log.debug('rebuilding stage vmtrack');

    if (this.vmStageTrackId) {
      this.vm.removeTrack(this.vmStageTrackId);
      this.vmStageTrackId = null;
    }

    const settings = this.videoEffectsSettings.stage;
    if (!settings || !settings.enabled) return;

    const umedia = new UnplayableVideoImpl(
      xDomainifyUrl(settings.config.mediaFormat.url)
    );

    umedia.media.muted = true;

    this.vmStageTrackId = this.vm.pushTrack(
      umedia.media,
      new TrackInitConfigBuilder()
        .setTimelineTimeStartMs(this.vm.playheadMs)
        .setDurationMs(settings.config.mediaFormat.length)
        .setLoop(true)
        .setZLayer(-1)
        .build()
    );

    await umedia.intoPlayable();
  }

  private async rebuildCameraTrack() {
    log.debug('rebuilding camera vmtrack');

    if (!this.cameraMediaStreamTrack) {
      if (this.vmCameraTrackId) {
        this.vm.removeTrack(this.vmCameraTrackId.vm);
        this.vmCameraTrackId = null;
      }
      return;
    }

    const configBuilder = new TrackInitConfigBuilder()
      .setDurationMs(Infinity)
      .setTimelineTimeStartMs(0)
      .setZLayer(0)
      .setBoundingBox(this.videoEffectsSettings.boundingBox);

    if (this.videoEffectsSettings.greenScreen.enabled) {
      configBuilder
        .setChromakey(
          GreenScreenValuesUtils.ToGLCompat(
            this.videoEffectsSettings.greenScreen
          )
        )
        .setRectMask(this.videoEffectsSettings.greenScreen.maskPct);
    }

    if (this.vmCameraTrackId) {
      if (this.vmCameraTrackId.raw === this.cameraMediaStreamTrack.id) {
        this.withFadeEffect(configBuilder);
        configBuilder
          .setTimelineTimeStartMs(this.vm.playheadMs)
          .addVideoOpacityEnvelope(
            { ms: 0, value: 0 },
            { ms: this.fadeMs, value: 0 },
            'out'
          )
          .addVideoOpacityEnvelope(
            { ms: this.fadeMs, value: 0 },
            { ms: this.fadeMs * 2, value: 1 },
            'in'
          );
        this.vm.patchTrack(this.vmCameraTrackId.vm, configBuilder.build());
        return;
      } else {
        this.vm.removeTrack(this.vmCameraTrackId.vm);
        this.vmCameraTrackId = null;
      }
    }

    const stream = new MediaStream([this.cameraMediaStreamTrack]);
    const unplayable = new UnplayableVideoImpl(stream);
    const vmTrackId = this.vm.pushTrack(
      unplayable.media,
      configBuilder.build()
    );
    this.vmCameraTrackId = {
      vm: vmTrackId,
      raw: this.cameraMediaStreamTrack.id,
    };

    // A stream does not need to wait for the first frame and is not seekable
    await unplayable.intoPlayable(false);
  }

  private async rebuildPodiumTrack() {
    log.debug('rebuilding podium vmtrack');

    if (this.vmPodiumTrack) {
      this.vm.removeTrack(this.vmPodiumTrack);
      this.vmPodiumTrack = null;
    }

    const settings = this.videoEffectsSettings.podium;
    if (!settings || !settings.enabled) return;

    const umedia = new UnplayableImageImpl(
      xDomainifyUrl(settings.config.mediaFormat.url)
    );

    const configBuilder = new TrackInitConfigBuilder()
      .setTimelineTimeStartMs(this.vm.playheadMs)
      .setDurationMs(settings.config.mediaFormat.length || Infinity)
      .setZLayer(2)
      .setTimelineTimeStartMs(this.vm.playheadMs);
    this.withFadeEffect(configBuilder);

    if (this.videoEffectsSettings.podiumBoundingBox) {
      configBuilder.setBoundingBox(this.videoEffectsSettings.podiumBoundingBox);
    }

    this.vmPodiumTrack = this.vm.pushTrack(umedia.media, configBuilder.build());

    await umedia.intoPlayable();
  }

  private withFadeEffect(configBuilder: TrackInitConfigBuilder) {
    return configBuilder
      .addVideoOpacityEnvelope(
        { ms: 0, value: 0 },
        { ms: this.fadeMs, value: 0 },
        'out'
      )
      .addVideoOpacityEnvelope(
        { ms: this.fadeMs, value: 0 },
        { ms: this.fadeMs * 2, value: 1 },
        'in'
      );
  }
}
