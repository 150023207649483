import { type UID } from 'agora-rtc-sdk-ng';
import { useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type CustomVideoQualityPreset } from '../../services/webrtc';
import { type Participant } from '../../types';
import { HostIcon } from '../icons/HostIcon';
import { LunaParkLogoStacked } from '../icons/LogoIcon';
import { MicrophoneOffIcon2 } from '../icons/MicrophoneOffIcon2';
import { Placeholder } from '../Participant';
import { useCohost, useMyInstance } from '../Player';
import { useUserStates } from '../UserContext';
import { useAudioEnabled } from '../Venue';
import {
  BaseLocalStreamView,
  useIsCoreChannelJoined,
  useRTCService,
} from '../WebRTC';
import {
  useCohostPosition,
  useCohostVisibility,
} from './CohostPositionManagerProvider';

function CohostAttachedMenu(props: { cohost: Participant }) {
  const { cohost } = props;
  return (
    <div
      className='
          absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2
          bg-dark-gray text-white font-bold text-sms
          px-2.5 py-1.5
          flex items-center gap-1.5
          rounded-lg
        '
    >
      <HostIcon />
      {cohost.firstName ?? cohost.username}
    </div>
  );
}

export function CohostStreamView() {
  const pos = useCohostPosition();
  return (
    <div
      className={`absolute ${pos.fullscreen ? 'w-full h-full' : ''}`}
      style={{
        left: pos.left,
        top: pos.top,
        // Prevent a flash while we're waiting for the measurement to be
        // made. Zeros will hide.
        visibility: pos.visible ? 'visible' : 'hidden',
        zIndex: pos.zIndex,
      }}
    >
      <CohostStreamViewInternal
        widthPx={pos.width}
        heightPx={pos.height}
        borderRadius={pos.borderRadius}
        fullscreen={pos.fullscreen}
      />
    </div>
  );
}

function CohostStreamViewInternal(props: {
  widthPx: number;
  heightPx: number;
  borderRadius: number;
  fullscreen: boolean;
}) {
  const cohost = useCohost();
  const cohostVisibility = useCohostVisibility();
  const previousVisibility = usePrevious(cohostVisibility);
  const me = useMyInstance();
  const cohostIsMe = cohost?.clientId === me?.clientId;

  if (!cohost || !me) return null;

  const noTransition =
    previousVisibility === 'placeholder' || cohostVisibility === 'placeholder';
  const hidden = cohostVisibility === 'hidden' && !cohostIsMe;
  const hiddenNamePlate =
    props.fullscreen || (cohostVisibility === 'placeholder' && !cohostIsMe);

  return (
    <div
      className={`relative ${props.fullscreen ? 'w-full h-full' : ''} ${
        hidden ? 'opacity-0' : 'opacity-100'
      } ${noTransition ? '' : 'transition-opacity duration-300'}`}
    >
      <div
        className={`
          box-content overflow-hidden relative ${
            !props.fullscreen
              ? 'border-[3px] border-solid border-white-001 m-px drop-shadow-lp-team-stream'
              : ''
          }
        `}
        style={{
          width: props.fullscreen ? '100%' : props.widthPx,
          height: props.fullscreen ? '100%' : props.heightPx,
          borderRadius: props.borderRadius,
        }}
      >
        {cohostIsMe ? (
          <>
            <CohostLocalStreamView
              id={cohost.clientId}
              videoQuality={props.fullscreen ? '720p' : '240p'}
            />
            <CohostHUD />
          </>
        ) : (
          <>
            {cohostVisibility === 'placeholder' && <CohostPlaceholderView />}
            <div
              className={`${
                cohostVisibility === 'placeholder' ? 'hidden' : ''
              }`}
            >
              <CohostRemoteStreamView />
            </div>
          </>
        )}
      </div>
      {!hiddenNamePlate && <CohostAttachedMenu cohost={cohost} />}
    </div>
  );
}

function CohostPlaceholderView() {
  return (
    <div className='w-full h-full flex flex-col items-center justify-center gap-2 bg-black'>
      <div className='px-9'>
        <LunaParkLogoStacked className='' />
      </div>
      <div className='px-4 text-2xs font-bold text-center text-icon-gray'>
        You’ll meet your host in just a few minutes!
      </div>
    </div>
  );
}

function CohostHUD() {
  const cohostVisibility = useCohostVisibility();
  const isVisible = cohostVisibility === 'visible';

  return (
    <div className='absolute inset-0 z-10 pointer-events-none'>
      <div
        className={`absolute inset-0 bg-black ${
          !isVisible ? 'opacity-60' : 'opacity-0'
        } transition-opacity`}
      />

      <div className='relative w-full h-full flex flex-col items-center justify-center gap-2 px-2 py-3 text-red-002 text-center font-bold text-2xs lp-sm:text-sms'>
        <CohostAmIMutedIndicator />
        {cohostVisibility === 'hidden'
          ? 'Players CANNOT see you'
          : cohostVisibility === 'placeholder'
          ? 'Players CANNOT see you, but see a placeholder'
          : ''}
      </div>
    </div>
  );
}

function CohostAmIMutedIndicator() {
  const { audio, micOpen } = useUserStates();
  if (audio && micOpen) return null;

  return (
    <div className='p-2.5 rounded-full ring-2 ring-red-002 bg-lp-black-001 animate-bounce'>
      <MicrophoneOffIcon2 className='w-8 h-8' />
    </div>
  );
}

function CohostRemoteStreamView() {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const cohost = useCohost();
  const memberId = cohost?.clientId;
  const rtcService = useRTCService('stage');
  const audioEnabled = useAudioEnabled();
  const getLatestAudioEnabled = useLiveCallback(() => audioEnabled);
  const channelJoined = useIsCoreChannelJoined('stage');

  useEffect(() => {
    if (!channelJoined) return;

    async function onPublished(uid: UID, mediaType: 'audio' | 'video') {
      if (uid !== memberId) return;

      if (mediaType === 'audio' && getLatestAudioEnabled()) {
        rtcService.playAudio(uid);
      }

      if (mediaType === 'video' && videoRef.current) {
        rtcService.playVideo(uid, videoRef.current);
        try {
          await rtcService.setRemoteVideoStreamType(uid, 0);
        } catch (error) {
          rtcService.log.error('setRemoteVideoStreamType failed', error);
        }
      }
    }

    async function onUnpublished(uid: UID, mediaType: 'audio' | 'video') {
      if (uid !== memberId) return;

      if (mediaType === 'audio') {
        rtcService.stopAudio(uid);
      }

      if (mediaType === 'video') {
        rtcService.stopVideo(uid);
      }
    }

    if (!memberId) return;

    const [audioTrack, videoTrack] = rtcService.getTracksByUid(memberId);
    if (audioTrack) onPublished(memberId, 'audio');
    if (videoTrack) onPublished(memberId, 'video');

    const offs: (() => void)[] = [];
    offs.push(rtcService.on('remote-user-published', onPublished));
    offs.push(rtcService.on('remote-user-unpublished', onUnpublished));

    return () => {
      offs.forEach((dispose) => dispose());
    };
  }, [channelJoined, getLatestAudioEnabled, memberId, rtcService]);

  useEffect(() => {
    return () => {
      if (!memberId) return;
      rtcService.stopAudio(memberId);
      rtcService.stopVideo(memberId);
    };
  }, [memberId, rtcService]);

  if (!memberId) return null;

  return (
    <div
      id={memberId}
      ref={videoRef}
      className={`w-full h-full absolute z-10 top-0 left-0`}
    >
      <Placeholder
        clientId={cohost.clientId}
        showLiteMode={false}
        noIndicators
      />
    </div>
  );
}

function CohostLocalStreamView(props: {
  id: string;
  videoQuality: CustomVideoQualityPreset;
}) {
  const rtc = useRTCService('stage');

  useEffect(() => {
    async function exec() {
      await rtc.setEncoderConfiguration(props.videoQuality, {
        persistConfig: true,
      });
    }
    exec();
  }, [rtc, props.videoQuality]);

  return (
    <BaseLocalStreamView
      id={`local-cohost-${props.id}`}
      publishVideoTrackIfChanged
      publishAudioTrackIfChanged
      rtcService={rtc}
      // cohost may need to interact with the on-stage people, so mirror is
      // force disabled.
      mirror={false}
      className={`w-full h-full relative`}
      fit='cover'
    ></BaseLocalStreamView>
  );
}
